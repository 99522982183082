var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-notices__component",attrs:{"width":"400px"}},[(_vm.isUserLoggedInAndHasRights && !_vm.initialRedirect)?_c('v-card',[_c('v-form',[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"text-h5 Anthrazit--text"},[_vm._v(_vm._s(_vm.$t('title')))])]),_c('v-card-text',[_c('d4y-table',{attrs:{"allowEdit":false,"allowDelete":false,"haveMoreActions":true,"searchLabel":_vm.$t('table_search_label'),"loadingFacet":_vm.loadingFacet,"tableHasFilterBtn":false,"tableHeaderText":_vm.tableText,"serverItemsLength":_vm.changeVouchers.total,"hasFooterBtn":false,"loading":_vm.changeVouchersIsLoading,"items":_vm.changeVouchers.items,"headers":_vm.headers,"options":_vm.changeVouchersSearchParams.dataOption,"isSearchDataEmpty":_vm.isAllFiltersEmpty,"filterFields":_vm.getFilterFields,"searchMenuObj":_vm.searchMenuObj},on:{"action:add":_vm.onAdd,"create:change_voucher":_vm.createVoucher,"click:edit":_vm.editChangeVoucher,"click:delete":_vm.deleteChangeVoucher,"update:options":_vm.changeVouchersUpdateOptions,"pdf:download":_vm.pdfDownload,"excel:download":_vm.excelDownload,"click:clear-all-filter":_vm.clearAllFilters,"search:chip":_vm.searchInRealObjects,"remove:chip":_vm.removeChip},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [(item.status !== null || item.status !== undefined)?_c('v-chip',{staticClass:"status__chip",class:_vm.statuses[item.status].class},[_vm._v(_vm._s(_vm.statuses[item.status].text)+" ")]):_vm._e()]}},{key:"int_status",fn:function(ref){
var item = ref.item;
return [((item.int_status !== null || item.int_status !== undefined) && item.int_status !=1)?_c('v-chip',{staticClass:"status__chipInStatus",class:_vm.internalStatuses[item.int_status].class},[_vm._v(_vm._s(_vm.internalStatuses[item.int_status].text)+" "+_vm._s(_vm._f("formatDateWithLocale")(item.int_status != 2 ? '' : item.wv_date,_vm.$i18n.locale))+" ")]):_vm._e()]}},{key:"aa_type_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.documentAeaName(item.aa_type_id))+" ")]}},{key:"reference_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"blue--text text-decoration-underline cursor_pointer",on:{"click":function($event){$event.stopPropagation();return _vm.openInvoiceInNewTab(item)}}},[_vm._v(_vm._s(item.reference_id))]),_c('div',{staticClass:"pl-2"},[(item.reference_id)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.newTabIcon?_vm.newTabIcon.src:false)?_c('v-img',_vm._g(_vm._b({staticClass:"cursor_pointer",attrs:{"contain":"","max-height":_vm.newTabIcon?_vm.newTabIcon.height:0,"max-width":_vm.newTabIcon?_vm.newTabIcon.width:0,"src":require('@/assets/'+_vm.newTabIcon.src)},on:{"click":function($event){$event.stopPropagation();return _vm.openInvoiceInNewTab(item)}}},'v-img',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('new_tab_title')))])]):_vm._e()],1)])]}},{key:"created",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue--text text-decoration-underline cursor_pointer",on:{"click":function($event){$event.stopPropagation();return _vm.editChangeVoucher(item)}}},[_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.created,_vm.$i18n.locale))+" ")])]}},{key:"ext_document_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.ext_document_date,_vm.$i18n.locale))+" ")])]}},{key:"due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.due_date,_vm.$i18n.locale))+" ")]}},{key:"gross_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.gross_amount).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + _vm.getCurrencySymbol(item.currency.toLowerCase()))+" ")]}},{key:"net_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.net_amount).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))+" ")]}},{key:"zr_number_supplier",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(" "+_vm._s(item.zr_number_supplier)+" ")]),_c('div',{staticClass:"pl-2"},[(item.zrNummer)?_c('v-tooltip',{attrs:{"color":_vm.supplierTooltipInfo && item.zrNummer ? '#909090' : 'transparent',"left":"","content-class":"custom-supplier-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"primary","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',{staticClass:"d-block",staticStyle:{"width":"400px"}},[_c('div',{staticStyle:{"color":"white","text-align":"left","width":"350px"}},[(_vm.supplierTooltipInfo)?_c('supplier-tooltip',{attrs:{"tooltipInfo":item}}):_vm._e()],1)])]):_vm._e()],1)])]}},{key:"more-actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.openAufhebung?_vm.openAufhebung.src:false)?_c('v-img',_vm._g(_vm._b({staticClass:"mt-2 cursor_pointer",class:[item.item.status == 3 ? '' : 'default-cursor'],attrs:{"contain":"","max-height":_vm.openAufhebung?_vm.openAufhebung.height:0,"max-width":_vm.openAufhebung?_vm.openAufhebung.width:0,"src":item.item.status == 3 ? require('@/assets/'+_vm.openAufhebung.src) : require('@/assets/'+_vm.openGreyAufhebung.src)}},'v-img',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn_cancellation')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({class:item.item.status == 0 || item.item.status == 4 ? 'ml-1 mr-1 mb-1 mt-1 cursor_pointer' : 'ml-2 mr-1 mb-1 mt-1 cursor_pointer',attrs:{"contain":"","height":item.item.status == 0 || item.item.status == 4 ? 30 : 36,"width":item.item.status == 0 || item.item.status == 4 ? 30 : 36,"src":item.item.status == 0 || item.item.status == 4 ? require('@/assets/'+_vm.editingCV.src) : require('@/assets/'+_vm.showingCV.src)},on:{"click":function($event){return _vm.editChangeVoucher(item.item)}}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn_editing')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",class:[item.item.status == 0 ? '' : 'default-cursor'],attrs:{"size":"28","color":(item.item.status == 0 ? 'success' : 'gray')},on:{"click":function($event){$event.stopPropagation();return _vm.approveChangeVoucher(item.item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-marked-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn_release')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('d4y-btn',_vm._g(_vm._b({class:[item.item.status == 0 || item.item.status == 4 ? '' : 'default-cursor'],attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteChangeVoucher(item.item)}}},'d4y-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"32","color":"Anthrazit"}},[_vm._v("mdi-delete-forever-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn_delete')))])])]}}],null,false,3194002452)})],1)],1)],1):(_vm.isUserLoggedInAndHasRights === false && (_vm.zrNummer === null || !_vm.isUserHasRightsForVouchers) && !_vm.initialRedirect)?_c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2","max-width":"600"}},[_c('v-card-title',[_c('span',{staticClass:"text-h4 font-weight-light"},[_vm._v(_vm._s(_vm.$t('no_access_title')))])]),_c('v-card-text',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('no_access_text'))+" ("),_c('a',{staticClass:"primary--text",attrs:{"href":"mailto:support@meinvme.de"}},[_vm._v("support@meinvme.de")]),_vm._v(") ")])],1)],1):_c('div',[_c('v-progress-circular',{attrs:{"size":70,"width":7,"indeterminate":"","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }