var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoices__component",attrs:{"width":"400px"}},[(_vm.isUserLoggedInAndHasRights && !_vm.initialRedirect && _vm.invoicesLoaded)?_c('v-card',[_c('v-form',[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"text-h5 Anthrazit--text"},[_vm._v(_vm._s(_vm.$t('title')))]),_c('span',{staticClass:"style_for_span"},[_vm._v(" BETA-Version")])]),_c('v-card-text',[_c('d4y-table',{attrs:{"loadingPdf":_vm.getInvoicePdfIsLoading,"loadingExcel":_vm.getInvoiceExcelIsLoading,"haveMoreActions":true,"searchLabel":_vm.$t('table_search_label'),"mitgliedList":_vm.mitgliedList,"tableHasFilterBtn":false,"tableHeaderText":_vm.tableText,"hasFooterBtn":false,"isFooterBtnDisabled":_vm.isHasSearchData,"loading":_vm.invoicesIsLoading,"loadingFacet":_vm.loadingFacet,"serverItemsLength":_vm.getInvoices.total,"items":_vm.items,"headers":_vm.headers,"options":_vm.invoicesSearchParams.dataOption,"isSearchDataEmpty":_vm.isAllFiltersEmpty,"searchMenuObj":_vm.searchMenuObj},on:{"update:options":_vm.selectionUpdateOptions,"click:clear-all-filter":_vm.clearAllFilters,"excel:download":_vm.excelDownload,"pdf:download":_vm.pdfDownload,"search:chip":_vm.searchInRealObjects,"remove:chip":_vm.removeChip},scopedSlots:_vm._u([{key:"ident_nr",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue--text text-decoration-underline cursor_pointer",on:{"click":function($event){$event.stopPropagation();return _vm.openInvoice(item)}}},[_vm._v(_vm._s(item.ident_nr))])]}},{key:"externe_belegdatum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.externe_belegdatum,_vm.$i18n.locale))+" ")]}},{key:"belegdatum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.belegdatum,_vm.$i18n.locale))+" ")]}},{key:"faelligkeitsdatum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.faelligkeitsdatum,_vm.$i18n.locale))+" ")]}},{key:"wochenabschluss_datum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.wochenabschluss_datum,_vm.$i18n.locale))+" ")]}},{key:"brutto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.brutto).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + _vm.getCurrencySymbol(item.waehrung.toLowerCase()))+" ")]}},{key:"netto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.netto).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + _vm.getCurrencySymbol(item.waehrung.toLowerCase()))+" ")]}},{key:"zr_nr_kreditor",fn:function(ref){
var item = ref.item;
return [(item.zrNummer)?_c('v-tooltip',{attrs:{"color":_vm.supplierTooltipInfo && item.zrNummer ? '#909090' : 'transparent',"left":"","content-class":"custom-supplier-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"primary","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',{staticClass:"d-block",staticStyle:{"width":"400px"}},[_c('div',{staticStyle:{"color":"white","text-align":"left","width":"350px"}},[(item.zrNummer)?_c('supplier-tooltip',{attrs:{"tooltipInfo":item}}):_vm._e()],1)])]):_vm._e()]}},{key:"belegtyp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.documentTypeName(item.belegtyp))+" ")]}},{key:"more-actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"size":"28"},on:{"click":function($event){$event.stopPropagation();return _vm.openInvoice(item.item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-square-edit-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn_editing')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.createAenderungsanzeigen && !_vm.isProductionEnv && (_vm.isUserInAzureAdminGroupGetter || (_vm.isUserOnlyGs && _vm.isUserHasRightsForVouchers)) ? _vm.createAenderungsanzeigen.src : false)?_c('v-img',_vm._g(_vm._b({staticClass:"mb-1 cursor_pointer",attrs:{"contain":"","max-height":_vm.createAenderungsanzeigen?_vm.createAenderungsanzeigen.height:0,"max-width":_vm.createAenderungsanzeigen?_vm.createAenderungsanzeigen.width:0,"src":item.item.belegtyp != 4 && item.item.belegtyp != 5 && item.item.belegtyp != 6 ? require('@/assets/'+_vm.createAenderungsanzeigen.src) : require('@/assets/'+_vm.noCreateAenderungsanzeigen.src)},on:{"click":function($event){$event.stopPropagation();return _vm.createVoucher(item.item)}}},'v-img',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('create_change_voucher')))])])]}}],null,false,137200645)})],1)],1)],1):(_vm.isUserLoggedInAndHasRights === false && (_vm.zrNummer === null || !_vm.isUserHasRightsForInvoices) && !_vm.initialRedirect && _vm.invoicesLoaded)?_c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2","max-width":"600"}},[_c('v-card-title',[_c('span',{staticClass:"text-h4 font-weight-light"},[_vm._v(_vm._s(_vm.$t('no_access_title')))])]),_c('v-card-text',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('no_access_text'))+" ("),_c('a',{staticClass:"primary--text",attrs:{"href":"mailto:support@meinvme.de"}},[_vm._v("support@meinvme.de")]),_vm._v(") ")])],1)],1):_c('div',[_c('v-progress-circular',{attrs:{"size":70,"width":7,"indeterminate":"","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }