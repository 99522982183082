export interface ChangeReasonType {
  index: number;
  i18nKey: string;
  name?: string;
}

export enum ChangeReasonNameKey {
  not_selectable = 'not_selectable',
  goods_not_ordered = 'goods_not_ordered',
  goods_not_yet_delivered = 'goods_not_yet_delivered',
  goods_complain = 'goods_complain',
  error_calc_or_condition = 'error_calc_or_condition',
  supplier_credit_note = 'supplier_credit_note',
  bonus_credit = 'bonus_credit',
  freight_allowance = 'freight_allowance',
  contract_penalty = 'contract_penalty',
  cost_debit = 'cost_debit',
  value_date_agreement = 'value_date_agreement',
}

const RootI18nKey = 'reason.';

export const changeReasonList: ChangeReasonType[] = [
  { index: 0, i18nKey: RootI18nKey + ChangeReasonNameKey.not_selectable },
  { index: 1, i18nKey: RootI18nKey + ChangeReasonNameKey.goods_not_ordered },
  { index: 2, i18nKey: RootI18nKey + ChangeReasonNameKey.goods_not_yet_delivered },
  { index: 3, i18nKey: RootI18nKey + ChangeReasonNameKey.goods_complain },
  { index: 4, i18nKey: RootI18nKey + ChangeReasonNameKey.error_calc_or_condition },
  { index: 5, i18nKey: RootI18nKey + ChangeReasonNameKey.supplier_credit_note },
  { index: 6, i18nKey: RootI18nKey + ChangeReasonNameKey.bonus_credit },
  { index: 7, i18nKey: RootI18nKey + ChangeReasonNameKey.freight_allowance },
  { index: 8, i18nKey: RootI18nKey + ChangeReasonNameKey.contract_penalty },
  { index: 9, i18nKey: RootI18nKey + ChangeReasonNameKey.cost_debit },
  { index: 10, i18nKey: RootI18nKey + ChangeReasonNameKey.value_date_agreement },
];

export default {
  changeReasonList,
};

// 0	Nicht auswählbar
// 1	Berechnete Ware war nicht bestellt
// 2	Berechnete Ware war noch nicht geliefert
// 3	Gelieferte Ware wird reklamiert
// 4	Rechenfehler oder falsche Kondition
// 5	Lieferantengutschrift
// 6	Bonusgutschrift
// 7	Frachtverguetung
// 8	Vertragsstrafe
// 9	Kostenbelastung
// 10	Valutavereinbarung
