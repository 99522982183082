import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import { ROUTES } from '@/router/routesEnum';
import zrStat, { StatusIndices as ZrStatus, zrStatusesList } from '@/shared/model/smallPayloadModels/zrStatus';
import wochenabschluesseSearchData, { WochenabschluesseSearchData } from '@/shared/model/smallPayloadModels/wochenabschluesseSearchData';
import DateField from '@/components/_common/date-field/DateField.vue';
import { CONST } from '@/shared/utils/Constants';
import { UserData } from '@/shared/model/userData';
import wochenabschluesse, { Wochenabschluesse } from '@/shared/model/wochenabschluesse';
import StatusDialog from './status-dialog/status-dialog.vue';
import { DefaultBackendHelper } from '@/shared/utils/backendHelper';
import GeneralUtils from '@/shared/utils/generalUtils';


const logger = new Logger('login');
const zrDocumentModule = namespace('zrDocument');
const authModule = namespace('auth');
const userDataModule = namespace('userData');
const wochenabschluesseData = namespace('wochenabschluesse');

@Component({
  components: { D4yTable, DateField },
})
export default class ZRDocuments extends Vue {
  @authModule.Action('canSeeAll')
  private actionCanSeeAll!: any;
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserSuperAdmin')
  private isUserSuperAdmin!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;
  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('isUserSupport')
  private isUserSupport!: any;
  @authModule.Getter('isInitialRedirect')
  private isInitialRedirectGetter!: any;
  @authModule.Getter('supplierTooltipInfo')
  private supplierTooltipInfo!: any;

  @authModule.Getter('isUserHasRightsForEndOfWeek')
  private isUserHasRightsForEndOfWeek!: any;

  @zrDocumentModule.Action('getZRDocumentsFacetSearch')
  private actionGetZRDocumentsFacetSearch!: any;
  @zrDocumentModule.Action('getZrDocumentsExcelReport')
  private actionGetZrDocumentsExcelReport!: any;
  @zrDocumentModule.Action('getZrDocumentsPdfReport')
  private actionGetZrDocumentsPdfReport!: any;

  @zrDocumentModule.Action('updateSearchData')
  private actionUpdateSearchData!: any;


  @userDataModule.Action('updateUserData')
  private actionUpdateUserData!: any;
  @userDataModule.Action('getUserData')
  private actionGetUserData!: any;

  @wochenabschluesseData.Getter('getWochenabschluesseSearchData')
  private getWochenabschluesseSearchData!: any;
  @wochenabschluesseData.Action('getWochenabschluesse')
  private actiongetWochenabschluesseData!: any;
  @wochenabschluesseData.Getter('getZRWochenabschluesse')
  private getZRWochenabschluesse!: any;

  @wochenabschluesseData.Getter('getWochenabschluesseSearchParams')
  private zrWochenabschluesseSearchParams!: any;
  @wochenabschluesseData.Getter('getZRRWochenabschluesseIsLoading')
  private zrRWochenabschluesseIsLoading!: boolean;
  @wochenabschluesseData.Action('updateWochenabschluesseSearchData')
  private actionUpdateWochenabschluesseSearchData!: any;
  @wochenabschluesseData.Action('getWochenabschluesseFacetSearch')
  private actionGetWochenabschluesseFacetSearch!: any;
  @wochenabschluesseData.Action('getWochenabschluesseFileDownloadLink')
  private actionGetWochenabschluesseFileDownloadLink!: any;

  @wochenabschluesseData.Action('createWochenabschluesseLogRecords')
  private actionCreateWochenabschluesseLogRecords!: any;

  @wochenabschluesseData.Getter('isWochenabschluesseSearchDataEmpty')
  private isWochenabschluesseSearchDataEmpty!: any;

  @wochenabschluesseData.Getter('getIsWochenabschluesseFileDownload')
  private isWochenabschluesseFileDownload!: boolean;
  @wochenabschluesseData.Getter('isSearchDataEmpty')
  private isSearchDataEmpty!: any;

  private previousTimeoutId: any = 0;
  private searchPayload: any = {};
  @Watch('getWochenabschluesseSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: WochenabschluesseSearchData, oldVal: WochenabschluesseSearchData) {
    console.log('watcherrrr ---------get WochenabschluesseSearchData-------onOptions FilterChanged------get WochenabschluesseSearchData--------------------------------------  ===>>>isWochenabschluesseSearchDataEmpty ', this.isWochenabschluesseSearchDataEmpty , 'newVal  ', newVal, ' oldVal ', oldVal);
    const areObjectsEqual = GeneralUtils.areObjectsEqual(newVal, oldVal, ['isUserInAzureAdminGroup']); // decided to ignore 2 props than include logic for them in `search-form` component
    console.log('----------------------get WochenabschluesseSearchData--------------onOptions FilterChanged------------------------  ===>>> 1 this.isQueryBeforeCreatedDataLoad ', this.isQueryBeforeCreatedDataLoad, '  areObjectsEqual ', areObjectsEqual);
    console.log('areObjectsEqual :>> ', areObjectsEqual);
    logger.debug(`----filter:${oldVal}->${newVal}`);
    logger.log('newVal :>> ', newVal);
    logger.log('oldVal :>> ', oldVal);

    if (areObjectsEqual || this.isQueryBeforeCreatedDataLoad) {
      return;
    }
    console.log('----------search-form-wochenabschluesse------------get WochenabschluesseSearchData----------------onOptions FilterChanged----------------------  ===>>> isShowAllData  2', newVal.isShowAllData);
    let payload: any = {
      wochenabschluesseSearchData: {
        zrNummer: newVal.zrNummer,        
        receiverNumbers: newVal.receiverNumbers,  
        titles: newVal.titles,
        isShowAllData: newVal.isShowAllData,
        datum: newVal.datum,
      },
    };
    this.searchPayload = payload.wochenabschluesseSearchData;
    console.log('------search-form-wochenabschluesse--------wochenabschluesse-filter--------get WochenabschluesseSearchData------------------------onOptions FilterChanged--------------  ===>>>wochenabschluesseSearchData 3 ', payload.wochenabschluesseSearchData);
    localStorage.setItem('wochenabschluesseSearchData', JSON.stringify(payload.wochenabschluesseSearchData));
 console.log('-------search-form-wochenabschluesse-------wochenabschluesse-filter--------get WochenabschluesseSearchData--------------- localStorage.getItemwochenabschluesse-filter ', localStorage.getItem('wochenabschluesse-filter'));
    if (!localStorage.getItem('wochenabschluesse-filter')) {
      this.zrWochenabschluesseSearchParams.filter = ''; // clear Odata filter, cleared here to avoid extra backend query from `@update:options="selectionUpdateOptions"`
    }

    // set correct payload for `download PDF/excel` request
    this.getWochenabschluesseSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getWochenabschluesseSearchData.zrNummer = this.getZrNummerDependOnRole();
    console.log('----------------------get WochenabschluesseSearchData-----------------------onOptions FilterChanged---------------  ===>>>  4 ');
    // ignore request to backend until rights checked and tabled showed
    // showed use after `localStorage.setItem('searchData'` to work properly
    if (!this.isUserLoggedInAndHasRights) return;

    payload.wochenabschluesseSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      console.log('this.zrNummer onOptionsFilterChanged :>> ', this.zrNummer);
      payload.wochenabschluesseSearchData.zrNummer = this.getZrNummerDependOnRole(); // this.zrNummer;
    }
    console.log('----------------------get WochenabschluesseSearchData-----------------------onOptions FilterChanged---------------  ===>>>   5');
    this.zrWochenabschluesseSearchParams.dataOption.page = 1; // (GSP-082) go to 1st page, when change filter
    console.log('-------------------------actionget WochenabschluesseData-----------------onOptions FilterChanged------------------  ===>>>payload   ', payload);
    await this.actiongetWochenabschluesseData(payload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });

    await this.updateWochenabschluesseSearchDataBasedOnLastUpdate();
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? (this.zrNummerWithRoles.endOfWeekZrNummer + '') || '-' : this.zrNummer + '';
    console.log('------------wochenabschluesse---------------get ZrNummerDependOnRole---------------------------------  ===>>> result  ', result);
    return result;
  }
  get isUserLoggedInAndHasRights() {
    return (
      (this.isUserInAzureAdminGroupGetter || (this.isUserGsUser && !!this.zrNummer)) && this.isUserHasRightsForEndOfWeek
    );
  }

  // avoid extra query from watcher on initial page load
  get endOfWeekLoaded() {
    return this.zrRWochenabschluesseIsLoading !== undefined;
  }
  get isQueryBeforeCreatedDataLoad() {
    return this.zrRWochenabschluesseIsLoading === undefined;
  }

  get tableText() {
    return `${this.getZRWochenabschluesse.total} Artikel`;
  }

  get initialRedirect() {
    return this.isInitialRedirectGetter;
  }

  get isAllFiltersEmpty() {
    return this.isWochenabschluesseSearchDataEmpty && !this.zrWochenabschluesseSearchParams.filter && !this.chipDataString;
  }

  get isEmptyMitglied() {
    return this.getWochenabschluesseSearchData?.mitglied?.length == 0 || !this.getWochenabschluesseSearchData?.mitglied;
  }

  get isEmptyMitgliedListForGsUser() {
    return this.isUserOnlyGs && this.isEmptyMitglied;
  }

  // (GSP-103) show user ZrNummer for `GsUser` if nothing selected in combobox
  get mitgliedList() {
    return this.isEmptyMitgliedListForGsUser
      ? this.getZrNummerDependOnRole()
      : this.getWochenabschluesseSearchData?.mitglied?.join(', ');
    // return  this.getSearchData?.mitglied?.join(', ');
  }

  async created() {
    await this.actionCanSeeAll().catch((err: any) => {
      logger.error(err);
    });
    
    if (this.initialRedirect) return;

    localStorage.removeItem('wochenabschluesseSearchDataLastUpdate'); // reset (make null) localsStorage update time on reload
    await this.actionGetUserData(CONST.emptyGuid).then((result: UserData) => {
      if (result?.wochenabschluesseSearchData) {
        logger.log('actionGetUserData result:>> ', result);        
      
        const wochenabschluesseSearchData = JSON.parse(result.wochenabschluesseSearchData);
        this.searchPayload = wochenabschluesseSearchData.wochenabschluesseSearchData;
        this.actionUpdateWochenabschluesseSearchData(wochenabschluesseSearchData.wochenabschluesseSearchData);
        localStorage.setItem('wochenabschluesse-filter', wochenabschluesseSearchData.odataFilter);
        if (wochenabschluesseSearchData.chipDataString) {
          localStorage.setItem('wochenabschluesse-chipdata', wochenabschluesseSearchData.chipDataString);
          this.parseLocalStorageChips(wochenabschluesseSearchData.chipDataString);
        }
      }
    });
    
    if (this.isUserLoggedInAndHasRights) {
      await this.getWochenabschluesseData();
    }
  }

  parseLocalStorageChips(chipDataStr: any) {
    const values = chipDataStr.split(',');
    this.zrWochenabschluesseSearchParams.andClauseFieldsIds = []; // (GSP-095) avoid duplication in `andClauseFieldsIds` (when return from any other component (any form, invoices list))
    values.forEach((element: string) => {
      const field = element.split(':')[0];
      const word = element.split(':')[1];
      const fieldNameTranslated = this.$i18n.tc(`facet_search.${field}`);
      this.chipData.push({
        chipText: `${fieldNameTranslated}: ${word}`,
        chipField: field,
        searchWord: word,
      });

      this.zrWochenabschluesseSearchParams.andClauseFieldsIds.push({
        chipField: field,
        searchWord: word,
      });
    });
  }

  private isMounted = false; // avoid initial extra request in `selectionUpdateOptions`
  async mounted() {
    console.log('--------------WochenabschluesseSearchDataEmpty------------- mounted---------------------------------  ===>>>  this.isWochenabschluesseSearchDataEmpty ', this.isWochenabschluesseSearchDataEmpty);
    this.isMounted = true;
  }

  // item = {
  //   supplier: 'ALFONS VENJAKOB GMBH & CO. KG MOEBELFABRIK',
  //   name: 'Beistelltisch Höhe K 49 cm_1800 Filz-Tablett aufgelegt',
  //   van: '123456',
  //   ean: '123456',
  //   han: '123456',
  //   preisbindung: '2022-04-14',
  // };
  // items = Array(10).fill(this.item);

  get items() {
    console.log('------------------------------------------------------------  ===>>>  this.getZRWochenabschluesse.items  ', this.getZRWochenabschluesse.items);
    return this.getZRWochenabschluesse.items.map((x:any,i: number) => { x.id = i; return x;});
  }

  private async getWochenabschluesseData() {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-------onOptions FilterChanged-----------------------get   WochenabschluesseData-------------------------------  ===>>> this.searchPayload  ', this.searchPayload);
    // don't take getter value in this case
    //this.searchPayload.isShowAllData = this.getWochenabschluesseSearchData.isShowAllData;
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }

    const payload = { searchParams: this.zrWochenabschluesseSearchParams, wochenabschluesseSearchData: this.searchPayload }; // sent search request from localStorage on reload
    await this.actiongetWochenabschluesseData(payload)
      .then((result: any) => {
        logger.log('result :>> ', result);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async getWochenabschluesseDataWithParams() {
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.searchPayload.isUserInOnlyGs = this.isUserOnlyGs;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }
    await this.actiongetWochenabschluesseData({
      searchParams: this.zrWochenabschluesseSearchParams,
      wochenabschluesseSearchData: this.searchPayload,
    }).then(async () => {
      await this.updateWochenabschluesseSearchDataBasedOnLastUpdate();
    });
  }

  private async updateWochenabschluesseSearchDataBasedOnLastUpdate() {
    let timeFromLastInvoiceSearchDataUpdateInSeconds = this.geTimeFromLastInvoiceSearchDataUpdateInSeconds();
    // avoid extra queries to database , but save immediately when clear all filters
    if (timeFromLastInvoiceSearchDataUpdateInSeconds > 1.5) {
      // || this.isInvoiceSearchDataEmpty) {
      await this.updateWochenabschluesseSearchDataInDatabase();
    } else {
      clearTimeout(this.previousTimeoutId);
      this.previousTimeoutId = setTimeout(() => this.updateWochenabschluesseSearchDataInDatabase(), 2000);
    }
  }

  async updateWochenabschluesseSearchDataInDatabase() {
    const userDataPayload = {
      wochenabschluesseSearchData: {
        wochenabschluesseSearchData: this.searchPayload,
        odataFilter: localStorage.getItem('wochenabschluesse-filter') || '',
        chipDataString: this.chipDataString || ''       
      },
      isWochenabschluesseSearch: true,
    };
    console.log('-search-form-wochenabschluesse---update WochenabschluesseSearchDataInDatabase-----wochenabschluesseSearchData action action GetUserData--------------------->>>:userDataPayload   ', userDataPayload);
    await this.actionUpdateUserData(userDataPayload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private geTimeFromLastInvoiceSearchDataUpdateInSeconds() {
    let timeFromLastInvoiceSearchDataUpdateInSeconds = 0;
    if (!localStorage.getItem('wochenabschluesseSearchDataLastUpdate')) {
      localStorage.setItem('wochenabschluesseSearchDataLastUpdate', new Date().getTime() + '');
    } else {
      const currentTime = new Date().getTime();
      timeFromLastInvoiceSearchDataUpdateInSeconds =
        (currentTime - +localStorage.getItem('wochenabschluesseSearchDataLastUpdate')!) / 1000;
      localStorage.setItem('wochenabschluesseSearchDataLastUpdate', new Date().getTime() + '');
    }

    return timeFromLastInvoiceSearchDataUpdateInSeconds;
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      align?: string;
      class?: string | string[];
      sortable?: boolean;
      width?: string;
    }[] = [
      {
        text: this.$t('anzahl'),
        value: 'expand-action',
        sortable: false,
        width: '10px',
        align: 'center'
      },
      { text: this.$t('auflistungsdatum_date'), value: 'end_of_week_date', width: '65px' , align: 'left'},
      { text: this.$t('mitglied'), value: 'full_name' },
    ];
    return headers;
  }

  private openZrDocument(item: any) {
    logger.log('------------------------------------------------------------------------------------------------------openZrDocument item :>> ', item);

    this.$router.push({
      name: ROUTES.zrDocument,
      params: { zrDocumentId: item.id },
    });
  }

  clearAllFilters() {
    console.log('---------------------------clearA llFilters---------------------------------  ===>>>   ');
    localStorage.removeItem('wochenabschluesseSearchData');
    localStorage.removeItem('wochenabschluesseSearchDataLastUpdate');

    console.log('-----------onOptions FilterChanged----------------clearA llFilters---------------------------------  ===>>>  this.isWochenabschluesseSearchDataEmpty ', this.isWochenabschluesseSearchDataEmpty);
    this.actionUpdateWochenabschluesseSearchData(wochenabschluesseSearchData.defaultData());
    console.log('---------------------------clearA llFilters---------------------------------  ===>>>  this.isWochenabschluesseSearchDataEmpty ', this.isWochenabschluesseSearchDataEmpty);
    this.resetAllFacetOptionsAndSearch();
  }

  private async selectionUpdateOptions(newVal: any, action: any) {
    console.log('-----search-form-wochenabschluesse---- Wochenabschluesse----selection UpdateOptions---------------------------------------------------------------------------------------selection UpdateOptions :>> ', newVal, '  action ', action);    
    if (action == 'search') {
      this.searchFacetModeInWochenabschluesse(newVal);
    } else if (action == 'reset') {
      this.resetAllFacetOptionsAndSearch();
      this.getWochenabschluesseData();
    } else {
      if (this.isMounted && this.zrWochenabschluesseSearchParams !== undefined) {
        this.zrWochenabschluesseSearchParams.dataOption = newVal;       
        this.getWochenabschluesseData();
      }
    }
  }

  private searchFacetModeInWochenabschluesse(e: any): void {
    const searchWord: string = e.filter;

    let selectedListItemId = document.querySelector('.v-list-item--highlighted .v-list-item__title')?.id;
    console.log('----search search FacetModeInWochenabschluesse--------------------------------------------------------  ===>>>selectedListItemId   ', selectedListItemId);
    if (selectedListItemId != undefined) {
      let menuItem = this.menuItems.find((x: any) => x.field == selectedListItemId);
      this.facetSearch = false;
      this.searchInRealObjects(menuItem);
      return;
    }
    this.searchWord = searchWord;
    // if user press "enter" key again then make search in all fields
    if (this.facetSearch && this.menuItems.length != 0) {
      this.facetSearch = false;
      // return;
    }
    // handle facet search for words more than 2 letters
    if (searchWord?.length >= 2) {
      // const { start, end } = this.getStartEndPeriodForSearch();
      this.loadingFacet = true;
      console.log('----WochenabschluesseSearchData------------CALL----------make  FacetSearch----------------------------------  ===>>>   ');
      this.makeFacetSearch(searchWord)
        .then((result: any) => {
          console.log('----WochenabschluesseSearchData------------CALL----------make  FacetSearch----------------------------------  ===>>> result  ', result);
          this.formatResultFromSearch(result);
          this.facetSearch = true;
          this.menuDisabled = false;
        })
        .finally(() => {
          this.loadingFacet = false;
        });
    } else if (searchWord?.trim().length === 0) {
      // load all invoices if press `Enter` with clear input
      console.log('-------------------------------search FacetModeInWochenabschluesse-----------------------------  ===>>>   ');
      this.getWochenabschluesseData();
    }
  }

  searchInRealObjects(item: any) {
    console.log('----searchInRealObjects-----wochenabschluesseSearchData action action GetUserData--------------------->>>:   ', item);
    this.searchInRealWochenabschluesse(item);
  }

  searchInRealWochenabschluesse(item: any) {
    console.log(
      '--------searchInRealWochenabschluesse searchInRealWochenabschluesse searchInRealWochenabschluesse searchInRealWochenabschluesse---------------------------  >>>:'
    );
    const searchParams = this.zrWochenabschluesseSearchParams; //this.searchParams;

    searchParams.andClauseFieldsIds.push({
      chipField: item.field,
      searchWord: this.searchWord,
    });
    if (item.field === 'all') {
      // searchParams.andClauseFieldsIds.push({
      //   chipField: item.field,
      //   searchWord: this.searchWord,
      // });
    }
    if (this.searchWord?.length >= 2 && item.title.length > 0) {
      // load all invoices if clear the input
      this.actiongetWochenabschluesseData({ searchParams, wochenabschluesseSearchData: this.getWochenabschluesseSearchData })
        .then((result: any) => {
          this.menuDisabled = true;
          if (item.field != 'all') {
            this.chipData.push({
              chipText: `${item.fieldTranslated}: ${this.searchWord}`,
              chipField: item.field,
              searchWord: this.searchWord,
            });
            localStorage.setItem('wochenabschluesse-chipdata', this.chipDataString);
            this.updateWochenabschluesseSearchDataBasedOnLastUpdate();
          }
        })
        .finally(() => {
          this.resetFacetMenu();
        });
    }
  }

  get isHasSearchData() {
    return this.getZRWochenabschluesse.total === 0 || this.zrRWochenabschluesseIsLoading || this.isSearchDataHasNoValues();
  }

  isSearchDataHasNoValues() {
    let values = Object.values(this.getWochenabschluesseSearchData);
    let result = values.some((x: any) => (x + '').length > 0);
    return !result;
  }

  excelDownload() {
    this.setZrNummerInPayloadIfEmpty();
    let payload = this.getWochenabschluesseSearchData;
    payload.searchTerm = this.zrWochenabschluesseSearchParams.filter;
    payload.chipDataString = this.chipDataString;
    payload.DateTimeTitle =
      this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
    payload.lang = this.$i18n.locale;
    this.actionGetZrDocumentsExcelReport(payload);
  }

  get statuses() {
    return {
      1: { text: this.$t('status_chip.new'), class: 'none' },
      2: { text: this.$t('status_chip.book'), class: 'green' },
      3: { text: this.$t('status_chip.not_book'), class: 'red' },
      4: { text: this.$t('status_chip.rejected'), class: 'red' },
      5: { text: this.$t('status_chip.posted'), class: 'green' },
      6: { text: this.$t('status_chip.error'), class: 'orange' },
    };
  }

  get apiStatuses() {
    return [4, 5, 6];
  }

  //#region Logic related to `edit-status`  dialog menu
  currentItem = wochenabschluesse.parse({});
  showMenu = false;
  x = 0;
  y = 0;

  onShowMenu(e: any, item: any) {
    this.currentItem = item;

    let target = e.target;
    // if click on the `chip text`use parent as target (chip element)
    if (e.target.className.includes('v-chip__content')) {
      target = e.target.parentElement;
    }

    const rectCoordinates = target.getBoundingClientRect();
    e.preventDefault();
    this.showMenu = false;
    // this.x = e.clientX;
    // this.y = e.clientY;
    this.x = rectCoordinates.left;
    this.y = rectCoordinates.bottom + 5;

    this.$nextTick(() => {
      this.showMenu = true;
    });
  }
  closeMenu() {
    this.showMenu = false;
  }
  //#endregion

  //#region Facet
  private facetSearch = false;
  private menuDisabled = true;
  private searchWord = '';
  private menuItems: any = [];
  private chipData: Array<any> = [];

  get chipDataString() {
    return this.chipData.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
  }

  removeChip(chipText: any) {
    console.log('--------------Wochenabschluesse---------------remove Chip-------------------------------  ===>>>   ');
    const index = this.chipData.findIndex((x) => x.chipText === chipText);
    this.chipData.splice(index, 1);
    const indexOdata = this.zrWochenabschluesseSearchParams.andClauseFieldsIds.findIndex((x: any) => x.chipText === chipText);
    this.zrWochenabschluesseSearchParams.andClauseFieldsIds.splice(index, 1);

    this.menuItems = [];
    this.menuDisabled = true;
    this.getWochenabschluesseData();
  }

  private loadingFacet = false;
  /**
   * Make `facet search` in `zrDocuments` to know in which `zrDocuments` fields specified word was found
   * show `No results` if word was not found in any fields
   * @param {KeyboardEvent} e as KeyboardEvent (contains word from input)
   */
  
  async makeFacetSearch(searchWord: any) {
    console.log('----------Wochenabschluesse SearchData-------------make FacetSearch-------------------------------------  ===>>>   ');
    let payload = {
      searchWord: searchWord,
      chipDataString: this.chipDataString,
    };
    // (GSP-102) always set correct payload for `ZrNummer` for GsUser to avoid show/search all records
    this.getWochenabschluesseSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getWochenabschluesseSearchData.zrNummer = this.getZrNummerDependOnRole();
    payload = { ...this.getWochenabschluesseSearchData, ...payload };
    console.log('----WochenabschluesseSearchData----------------------make  FacetSearch----------------------------------  ===>>>   ');
    return this.actionGetWochenabschluesseFacetSearch(payload);
  }


  /**
   * Make the actual search in the `zrDocuments` table with returning `zrDocuments` based on results from `facet search`
   * @param {object} Menu item `object` with title(shown in menu) and field(backend field name) properties
   */
  

  formatResultFromSearch(result: any) {
    const fieldsWithResult = Object.keys(result).filter((key) => result[key] > 0);
    this.resetMenuItems();
    fieldsWithResult.forEach((element: string) => {
      const fieldName = element.charAt(0).toUpperCase() + element.slice(1);

      const fieldNameTranslated = this.$i18n.tc(`facet_search.${fieldName}`);
      this.menuItems.push({
        title: `${fieldName}: ${this.searchWord} (${result[element]})`,
        titleTranslated: `${fieldNameTranslated}: ${this.searchWord} (${result[element]})`,
        fieldTranslated: fieldNameTranslated,
        field: fieldName,
      });
    });
    if (fieldsWithResult.length === 0) {
      this.menuItems.push({
        titleTranslated: this.$i18n.tc('facet_search.no_results'),
      });
      return;
    }
    this.menuItems.unshift({
      titleTranslated: this.$i18n.tc('facet_search.all'),
      field: 'all',
      title: 'All',
    });
  }

  /**
    Should reset `menuItems` before add result from the next search otherwise the previous result will be summed with current result
    */
  private resetMenuItems() {
    this.menuItems = [];
  }
  private resetChips() {
    this.chipData = [];
  }

  resetFacetMenu() {
    this.menuDisabled = true;
    this.facetSearch = false;
    this.searchWord = '';
  }

  resetAllFacetOptionsAndSearch() {
    this.resetChips();
    this.resetMenuItems();
    this.resetFacetMenu();
    this.zrWochenabschluesseSearchParams.andClauseFieldsIds = [];
    this.zrWochenabschluesseSearchParams.filter = '';
  }

  get searchMenuObj() {
    return {
      facetSearch: this.facetSearch,
      menuDisabled: this.menuDisabled,
      menuItems: this.menuItems,
      hasResults: this.hasResults,
      chipData: this.chipData,
    };
  }

  // on prod for some reason no request for `zrDocuments` and `zrNummer` is empty (load all records but should load 0)
  setZrNummerInPayloadIfEmpty() {
    // set correct payload for `download PDF/excel` request
    this.getWochenabschluesseSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getWochenabschluesseSearchData.zrNummer = this.getZrNummerDependOnRole();
  }

  pdfDownload() {
    this.setZrNummerInPayloadIfEmpty();
    let payload = this.getWochenabschluesseSearchData;
    payload.searchTerm = this.zrWochenabschluesseSearchParams.filter;
    payload.DateTimeTitle =
      this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
    payload.lang = this.$i18n.locale;
    payload.chipDataString = this.chipDataString;
    this.actionGetZrDocumentsPdfReport(payload);
  }

  get hasResults() {
    return this.menuItems.length > 0 && !!this.menuItems[0]?.field;
  }
  //#endregion

  getCurrencySymbol(currency: any) {
    let currencySymbol: string = '';
    if (currency == 'euro' || currency == 'eur' || currency == '') {
      currencySymbol = ' €';
    } else if (currency == 'chf') {
      currencySymbol = ' ₣';
    }

    return currencySymbol;
  }

  async downloadFile(file:any){
    console.log('------------------------------download File------------------------------  ===>>> filePath  ', file.filePath, ' fileName   ', file.fileName);
    //window.URL.revokeObjectURL(filePath);
    let payload = {
      FileName: 'beispiel_download.pdf', //file.fileName,
      BucketName: 'gsp-news-anlagen', //file.filePath,      
      IsSaveOnDisk: true
    }
    console.log('------------------------------download File------------------------------  ===>>>  payload   ', payload);
    await this.actionGetWochenabschluesseFileDownloadLink(payload).then((result: any) => {      
         
      
  })
  .catch((err: any) => {
      logger.error(err);
  });
  console.log('-----------------------------------isWochenabschluesse FileDownload-------------------------------');
  let payloadLog = {   
    documentid: file.documentId, //file.filePath,
  }
    console.log('-----------------------------------isWochenabschluesse FileDownload-------------------------------', this.isWochenabschluesseFileDownload, '  payloadLog ', payloadLog);
    await  this.actionCreateWochenabschluesseLogRecords(payloadLog);
  
  }

}
